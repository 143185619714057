import React, { useEffect, useState } from "react";
import { View, Text, StyleSheet, Image, TouchableOpacity, ImageBackground, TextInput } from "react-native";
import { useNavigation } from "@react-navigation/native";
import BouncyCheckbox from "react-native-bouncy-checkbox";
import { Icons, background } from "../../assets";
import { Fonts, Styles } from "../../styles";
import { useGlobalState } from "../../shared/state";
import { I18N } from "../../shared/util";
import LayoutWrapper from "../../components/LayoutWrapper/LayoutWrapper";
import ProductService from "../../shared/services/products-service";
import TextTitle from "../../components/Text/TextTitle";
import ScannerService from "../../shared/services/scanner-service";
import ScannerCoreService from "../../shared/services/core-services";

const OrthopedicShoesOptionsModal: React.FC = (route): JSX.Element => {
  const { params } = route.route;
  // State variables
  const [Colors] = useGlobalState("colors");
  const [legLengthCompensation15LeftCheckBox, setLegLengthCompensation15LeftCheckBox] = useState<boolean>(false);
  const [legLengthCompensation15RightCheckBox, setLegLengthCompensation15RightCheckBox] = useState<boolean>(false);
  const [legLengthCompensation15LeftTextInput, setLegLengthCompensation15LeftTextInput] = useState<string>("");
  const [legLengthCompensation15RightTextInput, setLegLengthCompensation15RightTextInput] = useState<string>("");
  const [legLengthCompensation3LeftCheckBox, setLegLengthCompensation3LeftCheckBox] = useState<boolean>(false);
  const [legLengthCompensation3RightCheckBox, setLegLengthCompensation3RightCheckBox] = useState<boolean>(false);
  const [legLengthCompensation3LeftTextInput, setLegLengthCompensation3LeftTextInput] = useState<string>("");
  const [legLengthCompensation3RightTextInput, setLegLengthCompensation3RightTextInput] = useState<string>("");
  const [inOutEdgeElevationLeftCheckBox, setInOutEdgeElevationLeftCheckBox] = useState<boolean>(false);
  const [inOutEdgeElevationRightCheckBox, setInOutEdgeElevationRightCheckBox] = useState<boolean>(false);
  const [inOutEdgeElevationLeftTextInput, setInOutEdgeElevationLeftTextInput] = useState<string>("");
  const [inOutEdgeElevationRightTextInput, setInOutEdgeElevationRightTextInput] = useState<string>("");
  const [corkWedgeLeftCheckBox, setCorkWedgeLeftCheckBox] = useState<boolean>(false);
  const [corkWedgeRightCheckBox, setCorkWedgeRightCheckBox] = useState<boolean>(false);
  const [corkWedgeLeftTextInput, setCorkWedgeLeftTextInput] = useState<string>("");
  const [corkWedgeRightTextInput, setCorkWedgeRightTextInput] = useState<string>("");
  const [haglundFerseLeftCheckBox, setHaglundFerseLeftCheckBox] = useState<boolean>(false);
  const [haglundFerseRightCheckBox, setHaglundFerseRightCheckBox] = useState<boolean>(false);
  const [haglundFerseLeftTextInput, setHaglundFerseLeftTextInput] = useState<string>("");
  const [haglundFerseRightTextInput, setHaglundFerseRightTextInput] = useState<string>("");
  const [wheelingSoleCheckBox, setWheelingSoleCheckBox] = useState<boolean>(false);
  const [butterflyRollCheckBox, setButterflyRollCheckBox] = useState<boolean>(false);
  const [wheelingSoleTextInput, setWheelingSoleTextInput] = useState<string>("");
  const [butterflyRollTextInput, setButterflyRollTextInput] = useState<string>("");

  // Other necessary state and functions
  const [language] = useGlobalState("language");
  const [currentScanner] = useGlobalState("currentScanner");
  const [currentUser] = useGlobalState("currentUser");
  const [customerStoreID] = useGlobalState("customerStoreID");
  const [selectedShoe] = useGlobalState("selectedShoe");
  const storeID = customerStoreID || currentScanner.storeid;
  const i18n = I18N(language);
  const navigation = useNavigation();
  const cartUuid = params && params.cartUUID;
  const orthoInsoleAdded = params && params.orthoInsoleAdded;
  const insoleAppliancesID = params && params.insoleAppliancesID;
  const orderNr = params && params.orderNr;

  const [isSuccessful, setIsSuccessful] = useState<boolean>(false);
  // is set to params.supportsAdaptions because supportsAdaptions is true if the shoe supports shoe adaptions
  // and show success needs to be hidden if shoe adaption is supported
  const [isShowSuccessScreen, setIsShowSuccessScreen] = useState<boolean>(!params.supportsAdaptions);
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);

  const addShoeAdaption = (cartUuid: string, additional_product_gtin: string, applianceID: string, comments: string) => {
    const data = {
      type: "shoe-adaption",
      additional_product_gtin,
      comments,
      appliances_id: applianceID,
    };

    return ProductService.addCartItem(cartUuid, data);
  };

  const mapShoeCategoryForElten = (onefidCategory: string) => {
    if (onefidCategory === "low-work-shoes") {
      return "Halbschuhe";
    } else if (onefidCategory === "open-work-shoes") {
      return "Sandale";
    } else if (onefidCategory === "work-boots") {
      return "Stiefel";
    }
    return "";
  };

  const handleSubmission = () => {
    const conditions = [
      { check: legLengthCompensation15LeftCheckBox, code: "4030839382899", text: legLengthCompensation15LeftTextInput },
      { check: legLengthCompensation15RightCheckBox, code: "4030839382882", text: legLengthCompensation15RightTextInput },
      { check: legLengthCompensation3LeftCheckBox, code: "4030839382912", text: legLengthCompensation3LeftTextInput },
      { check: legLengthCompensation3RightCheckBox, code: "4030839382905", text: legLengthCompensation3RightTextInput },
      {
        check: inOutEdgeElevationLeftCheckBox && inOutEdgeElevationRightCheckBox,
        code: "4030839382943",
        text: `Links: ${inOutEdgeElevationLeftTextInput} , Rechts: ${inOutEdgeElevationRightTextInput}`,
      },
      {
        check: inOutEdgeElevationLeftCheckBox && !inOutEdgeElevationRightCheckBox,
        code: "4030839382967",
        text: inOutEdgeElevationLeftTextInput,
      },
      {
        check: !inOutEdgeElevationLeftCheckBox && inOutEdgeElevationRightCheckBox,
        code: "4030839382950",
        text: inOutEdgeElevationRightTextInput,
      },
      { check: corkWedgeLeftCheckBox, code: "4030839382981", text: corkWedgeLeftTextInput },
      { check: corkWedgeRightCheckBox, code: "4030839382974", text: corkWedgeRightTextInput },
      {
        check: haglundFerseLeftCheckBox && haglundFerseRightCheckBox,
        code: "4030839382998",
        text: `Links: ${haglundFerseLeftTextInput} , Rechts: ${haglundFerseRightTextInput}`,
      },
      {
        check: haglundFerseLeftCheckBox && !haglundFerseRightCheckBox,
        code: "4030839383018",
        text: haglundFerseLeftTextInput,
      },
      {
        check: !haglundFerseLeftCheckBox && haglundFerseRightCheckBox,
        code: "4030839383001",
        text: haglundFerseRightTextInput,
      },
      { check: wheelingSoleCheckBox, code: "4030839382929", text: wheelingSoleTextInput },
      { check: butterflyRollCheckBox, code: "4030839382936", text: butterflyRollTextInput },
    ];

    const relevantConditions = conditions.filter((condition) => condition.check);

    if (relevantConditions.length > 0) {
      ProductService.addCartItem(cartUuid, { type: "shoe", gtin: selectedShoe.gtin })
        .then((response: any) => {
          relevantConditions.forEach((condition) => {
            addShoeAdaption(cartUuid, condition.code, response.appliances_id, condition.text);
          });

          setIsSuccessful(true);
        })
        .catch(() => setShowErrorMessage(true));
    } else {
      setIsSuccessful(true);
    }
  };

  useEffect(() => {
    if (isSuccessful && !showErrorMessage) {
      setIsShowSuccessScreen(true);
    }
  }, [isSuccessful, showErrorMessage]);

  const shoeOptions: any = [
    {
      value: "legLengthCompensation1,5cm",
      checkBoxLeft: legLengthCompensation15LeftCheckBox,
      checkBoxRight: legLengthCompensation15RightCheckBox,
      setCheckBoxLeft: setLegLengthCompensation15LeftCheckBox,
      setCheckBoxRight: setLegLengthCompensation15RightCheckBox,
      setTextInputLeft: setLegLengthCompensation15LeftTextInput,
      setTextInputRight: setLegLengthCompensation15RightTextInput,
    },
    {
      value: "legLengthCompensation3cm",
      checkBoxLeft: legLengthCompensation3LeftCheckBox,
      checkBoxRight: legLengthCompensation3RightCheckBox,
      setCheckBoxLeft: setLegLengthCompensation3LeftCheckBox,
      setCheckBoxRight: setLegLengthCompensation3RightCheckBox,
      setTextInputLeft: setLegLengthCompensation3LeftTextInput,
      setTextInputRight: setLegLengthCompensation3RightTextInput,
    },
    {
      value: "inOutEdgeElevation",
      checkBoxLeft: inOutEdgeElevationLeftCheckBox,
      checkBoxRight: inOutEdgeElevationRightCheckBox,
      setCheckBoxLeft: setInOutEdgeElevationLeftCheckBox,
      setCheckBoxRight: setInOutEdgeElevationRightCheckBox,
      setTextInputLeft: setInOutEdgeElevationLeftTextInput,
      setTextInputRight: setInOutEdgeElevationRightTextInput,
    },
    {
      value: "corkWedge",
      checkBoxLeft: corkWedgeLeftCheckBox,
      checkBoxRight: corkWedgeRightCheckBox,
      setCheckBoxLeft: setCorkWedgeLeftCheckBox,
      setCheckBoxRight: setCorkWedgeRightCheckBox,
      setTextInputLeft: setCorkWedgeLeftTextInput,
      setTextInputRight: setCorkWedgeRightTextInput,
    },
    {
      value: "haglundFerse",
      checkBoxLeft: haglundFerseLeftCheckBox,
      checkBoxRight: haglundFerseRightCheckBox,
      setCheckBoxLeft: setHaglundFerseLeftCheckBox,
      setCheckBoxRight: setHaglundFerseRightCheckBox,
      setTextInputLeft: setHaglundFerseLeftTextInput,
      setTextInputRight: setHaglundFerseRightTextInput,
    },
  ];
  const folderPath = currentScanner && currentScanner.folderPath;
  const handleSuccessScreenSubmission = async () => {
    const device = await ScannerCoreService.getScanDeviceInfo(currentScanner && currentScanner.deviceID);
    ScannerCoreService.getScanDeviceInfo(currentScanner && currentScanner.deviceID)
    .then()
    orthoInsoleAdded &&
      ScannerService.moveFileFromScannerHost(
        folderPath,
        currentScanner.gpManagerPath,
        currentScanner.gpManagerParams,
        currentUser.firstName,
        currentUser.lastName,
        currentUser.dateOfBirth,
        insoleAppliancesID,
        new Date().toLocaleString(),
        storeID,
        device.device_identifier,
        mapShoeCategoryForElten(selectedShoe.categoryId),
        selectedShoe.name,
        selectedShoe.size.eu,
        orderNr
      );
    
      const orderData = {
      type: "elten-ortho",
      store_id: storeID,
      employee_number: currentUser.employeeNumber,
    }

    ProductService.completeOrder(cartUuid, orderData);

    navigation.navigate("MainApp");
  };

  return (
    <ImageBackground source={background} style={Styles.backgroundImage} imageStyle={{ opacity: 0.4 }}>
      <LayoutWrapper showHeaderCloseButton={false} showHeaderLogo={true} showBackButton={true}>
        <View style={styles.modalWrapper}>
          {!isShowSuccessScreen && (
            <View style={styles.formWrapper}>
              <Text style={[styles.titleText, { marginBottom: "1%" }]}>{i18n.t("shoeAdaptionsModalTitle")}</Text>
              <View style={styles.leftRightTextWrapper}>
                <View style={{ width: "35%" }} />
                <View style={styles.leftRightText}>
                  <Text style={styles.titleText}>{i18n.t("scan.left")}</Text>
                  <Text style={styles.titleText}>{i18n.t("scan.right")}</Text>
                </View>
              </View>
              <View style={styles.optionsViewWrapper}>
                {shoeOptions.map((option) => {
                  return (
                    <View style={styles.shoeOptionsWrapper} key={option.value}>
                      <View style={styles.infoView}>
                        <View style={styles.inputsWrapper}>
                          <TextTitle fontSize={12} text={i18n.t(option.value)} />
                        </View>
                      </View>
                      <View style={styles.checkboxView}>
                        <BouncyCheckbox
                          textComponent
                          innerIconStyle={{ borderColor: Colors.mainColorLight.color }}
                          fillColor={Colors.mainColorLight.color}
                          isChecked={option.checkBoxLeft}
                          onPress={() => option.setCheckBoxLeft(!option.checkBoxLeft)}
                          size={45}
                        />
                      </View>
                      <View style={[styles.inputsWrapper, { width: "23%", height: "100%" }]}>
                        <TextInput onChangeText={(text) => option.setTextInputLeft(text)} style={styles.textInput} placeholder={i18n.t("comments")} secureTextEntry={false} autoCorrect={false} />
                      </View>
                      <View style={styles.checkboxView}>
                        <BouncyCheckbox
                          textComponent
                          innerIconStyle={{ borderColor: Colors.mainColorLight.color }}
                          fillColor={Colors.mainColorLight.color}
                          isChecked={option.checkBoxRight}
                          onPress={() => option.setCheckBoxRight(!option.checkBoxRight)}
                          size={45}
                        />
                      </View>
                      <View style={[styles.inputsWrapper, { width: "23%", height: "100%" }]}>
                        <TextInput onChangeText={(text) => option.setTextInputRight(text)} style={styles.textInput} placeholder={i18n.t("comments")} secureTextEntry={false} autoCorrect={false} />
                      </View>
                    </View>
                  );
                })}
                <View style={styles.shoeOptionsWrapper}>
                  <View style={styles.infoView}>
                    <View style={styles.inputsWrapper}>
                      <TextTitle text={i18n.t("wheelingSole")} />
                    </View>
                  </View>
                  <View style={{ width: "15%", height: "100%" }} />
                  <View style={styles.checkboxView}>
                    <BouncyCheckbox
                      textComponent
                      innerIconStyle={{ borderColor: Colors.mainColorLight.color }}
                      fillColor={Colors.mainColorLight.color}
                      isChecked={wheelingSoleCheckBox}
                      onPress={() => setWheelingSoleCheckBox(!wheelingSoleCheckBox)}
                      size={45}
                    />
                  </View>
                  <View style={[styles.inputsWrapper, { width: "23%", height: "100%" }]}>
                    <TextInput onChangeText={(text) => setWheelingSoleTextInput(text)} style={styles.textInput} placeholder={i18n.t("comments")} secureTextEntry={false} autoCorrect={false} />
                  </View>
                  <View style={{ width: "15%", height: "100%" }} />
                </View>
                {selectedShoe && !["S1P", "S3"].includes(selectedShoe.safety_class) && (
                  <View style={styles.shoeOptionsWrapper}>
                    <View style={styles.infoView}>
                      <View style={styles.inputsWrapper}>
                        <TextTitle text={i18n.t("butterflyRoll")} />
                      </View>
                    </View>
                    <View style={{ width: "15%", height: "100%" }} />
                    <View style={styles.checkboxView}>
                      <BouncyCheckbox
                        textComponent
                        innerIconStyle={{ borderColor: Colors.mainColorLight.color }}
                        fillColor={Colors.mainColorLight.color}
                        isChecked={butterflyRollCheckBox}
                        onPress={() => setButterflyRollCheckBox(!butterflyRollCheckBox)}
                        size={45}
                      />
                    </View>
                    <View style={[styles.inputsWrapper, { width: "23%", height: "100%" }]}>
                      <TextInput onChangeText={(text) => setButterflyRollTextInput(text)} style={styles.textInput} placeholder={i18n.t("comments")} secureTextEntry={false} autoCorrect={false} />
                    </View>
                    <View style={{ width: "15%", height: "100%" }} />
                  </View>
                )}
              </View>
              <TouchableOpacity style={[styles.button, { backgroundColor: Colors.mainColorDark.color, alignSelf: "flex-end" }]} onPress={handleSubmission}>
                <Text style={styles.buttonText}>{i18n.t("createProcess")}</Text>
              </TouchableOpacity>
              {showErrorMessage && <Text style={styles.error}>An error happened please try again later</Text>}
            </View>
          )}
          {/* {isShowSuccessScreen && !showErrorMessage && ( */}
          {isShowSuccessScreen && (
            <View style={styles.contentWrapper}>
              <Image style={styles.imageContainer} source={Icons.checkmarkIconCircle} />
              <Text style={[styles.successText, Fonts.mLight, Colors.black, Fonts.center]}>{i18n.t("orthoOrderSuccessMessage")}</Text>

              <TouchableOpacity style={[styles.button, { backgroundColor: Colors.mainColorDark.color, alignSelf: "center" }]} onPress={handleSuccessScreenSubmission}>
                <Text style={styles.buttonText}>{i18n.t("next")}</Text>
              </TouchableOpacity>
            </View>
          )}
        </View>
      </LayoutWrapper>
    </ImageBackground>
  );
};

const styles = StyleSheet.create({
  closeButton: {
    width: "7%",
    height: "12%",
    position: "absolute",
    right: -60,
    top: -20,
    zIndex: 10,
    paddingTop: "3%",
    paddingLeft: "2%",
  },
  iconContainer: {
    width: "100%",
    height: "100%",
    resizeMode: "contain",
  },
  modalWrapper: {
    flex: 1,
    width: "75%",
    flexDirection: "row",
    justifyContent: "center",
    paddingLeft: "1.6%",
    alignSelf: "center",
  },
  formWrapper: {
    width: "100%",
    height: "100%",
    padding: "2%",
    alignSelf: "center",
    backgroundColor: "white",
  },
  inputsWrapper: {
    width: "100%",
    height: "100%",
    paddingLeft: "1%",
    paddingRight: "1%",
    backgroundColor: "#f0eff1",
    borderRadius: 6,
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    alignSelf: "center",
    fontSize: 72,
  },
  titleText: {
    fontFamily: "Montserrat-Black",
    fontSize: 21,
    fontWeight: "900",
    textAlign: "center",
    textTransform: "uppercase",
  },
  button: {
    width: 195,
    height: 42,
    borderRadius: 17,
    justifyContent: "center",
    alignItems: "center",
  },
  buttonText: {
    fontFamily: "Montserrat-Regular",
    fontSize: 12,
    textAlign: "center",
    color: "#ffffff",
    textTransform: "uppercase",
  },
  optionsViewWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    height: "85%",
  },
  infoView: {
    width: "25%",
    height: "90%",
  },
  textInput: {
    width: "99%",
    height: "99%",
  },
  checkboxView: {
    width: "7%",
    height: "auto",
    alignItems: "center",
  },
  leftRightTextWrapper: {
    justifyContent: "space-around",
    alignItems: "center",
    width: "100%",
    height: "5%",
    flexDirection: "row",
  },
  leftRightText: {
    width: "65%",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  shoeOptionsWrapper: {
    justifyContent: "space-around",
    alignItems: "center",
    width: "100%",
    height: "14%",
    flexDirection: "row",
  },
  contentWrapper: {
    width: "45%",
    height: "75%",
    alignSelf: "center",
    justifyContent: "space-evenly",
  },
  imageContainer: {
    resizeMode: "contain",
    height: 100,
    width: 150,
    alignSelf: "center",
  },
  successText: {
    fontSize: 29,
    fontWeight: "300",
    lineHeight: 50,
  },
  error: {
    fontFamily: "Montserrat-Regular",
    fontSize: 12,
    textAlign: "center",
    textTransform: "uppercase",
    color: "red",
  },
});

export default OrthopedicShoesOptionsModal;
