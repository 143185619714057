import React, { useEffect, useState } from "react";
import { View, Text, StyleSheet, Image, TouchableOpacity, ImageBackground } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { Picker } from "@react-native-picker/picker";
import { Icons, background } from "../../assets";
import { Fonts, Styles } from "../../styles";
import { useGlobalState } from "../../shared/state";
import { I18N } from "../../shared/util";
import ScannerCoreService from "../../shared/services/core-services";
import LayoutWrapper from "../../components/LayoutWrapper/LayoutWrapper";
import ProductService from "../../shared/services/products-service";
import { insoleCategories } from "./InsoleRecommendation";

const CreationModal: React.FC<any> = (route): JSX.Element => {
  const { params } = route.route;
  // State variables
  const [Colors] = useGlobalState("colors");
  const [currentMeasurements] = useGlobalState("currentMeasurements");
  const [currentUser] = useGlobalState("currentUser");
  const [currentScan] = useGlobalState("currentScan");
  const [currentScanner] = useGlobalState("currentScanner");
  const [newScanState] = useGlobalState("newScanState");
  const [insoleCreated, setInsoleCreated] = useState<boolean>(false);
  const [shoeSize, setShoeSize] = useState<string>("");
  const [contourLeadingSizeSystem, setContourLeadingSizeSystem] = useState<string | null>("");
  const [weight, setWeight] = useState<number>(35);
  const [insoleType, setInsoleType] = useState<string>("standard-sport");
  const [errorMessage, setErrorMessage] = useState<any>(null);
  const [contours, setContours] = useState<any>(null);
  const [sizes, setSizes] = useState<React.ReactNode[]>([]);
  const [recommendation, setRecommendation] = useState<any>();
  const [sizeRecommendation, setSizeRecommendation] = useState<any>();
  const [insole, setInsole] = useState<string>("");
  const [cartUuid, setCartUuid] = useState<string>("");

  // Other necessary state and functions
  const [language] = useGlobalState("language");
  const i18n = I18N(language);
  const navigation = useNavigation();
  const [contourItems, setContourItems] = useState([]);
  const storeID = currentScanner.storeid;

  useEffect(() => {
    const getSimpleSizeRecommendation = async () => {
      let identifier = "";
      if (currentUser) {
        identifier = currentUser.email;
      } else if (currentScan) {
        identifier = currentScan.customer_uuid;
      }

      const brand = "default_sizing_table";
      const category = "sneaker";

      try {
        const simpleSizeRecommendation = await ProductService.fetchSimpleRecommendationByOnefid(identifier, brand, category);
        setSizeRecommendation(simpleSizeRecommendation);
      } catch (error) {
        console.log("simple size recommendation error", error);
      }
    };

    getSimpleSizeRecommendation();
  }, [currentUser, currentScan]);

  useEffect(() => {
    if (currentMeasurements) {
      var insoleCategoryIndex = Math.min(
        insoleCategories.indexOf(currentMeasurements.measurements[0].insole_recommendation),
        insoleCategories.indexOf(currentMeasurements.measurements[1].insole_recommendation)
      );
      setInsole(insoleCategories[insoleCategoryIndex]);
    }
  }, [currentMeasurements]);

  const gender = currentUser ? currentUser.gender : newScanState.gender === "male" ? "m" : "f";
  const genderForInsoleFilter = gender === "f" ? "female" : "unisex";
  var newInsoleDataStructure: any = currentScanner.insoleRecommendation && currentScanner.insoleRecommendation.insoles[genderForInsoleFilter];
  const lowInsole = newInsoleDataStructure ? newInsoleDataStructure["low"] : currentScanner.insoleRecommendation.insoles.find((insole: any) => insole.id.toLowerCase() === "low");
  const midInsole = newInsoleDataStructure ? newInsoleDataStructure["mid"] : currentScanner.insoleRecommendation.insoles.find((insole: any) => insole.id.toLowerCase() === "mid");
  const highInsole = newInsoleDataStructure ? newInsoleDataStructure["high"] : currentScanner.insoleRecommendation.insoles.find((insole: any) => insole.id.toLowerCase() === "high");

  useEffect(() => {
    if (insole) {
      if (insole === "low") setRecommendation(lowInsole);
      if (insole === "mid") setRecommendation(midInsole);
      if (insole === "high") setRecommendation(highInsole);
    }
  }, [insole]);

  useEffect(() => {
    const fetchContourIDs = async () => {
      try {
        const response = await ProductService.getInsoleContourIDs();
        const contours = response.results;

        setContours(contours);

        const items = contours.map((contour) => <Picker.Item key={contour.contour_id} label={contour.name} value={contour.contour_id} />);
        const firstInsoleTypeItem = contours[0].contour_id;
        setInsoleType(firstInsoleTypeItem);
        setContourItems(items);
      } catch (error) {
        console.error("Error fetching insole contour IDs:", error);
      }
    };

    fetchContourIDs();
  }, []); // This effect runs only once on mount

  useEffect(() => {
    let sizesArray: JSX.Element[] = [<Picker.Item label="" value="" key="" />];
    let newShoeSize = ""; // Default value if not found
    let newContourLeadingSizeSystem = null;

    if (contours && contours.length > 0) {
      if (contours.length === 1) {
        // If only one contour, directly iterate over its sizes
        contours[0].sizes.forEach((size) => {
          sizesArray.push(<Picker.Item label={size.shoe_size} value={size.shoe_size} key={size.shoe_size} />);
          if (Object.values(size).includes(sizeRecommendation[contours[0].leading_system].toString())) {
            newShoeSize = sizeRecommendation[contours[0].leading_system];
          }
        });
        newContourLeadingSizeSystem = contours[0].leading_system;
      } else {
        contours.forEach((contour) => {
          if (contour.contour_id === insoleType) {
            contour.sizes.forEach((size) => {
              sizesArray.push(<Picker.Item label={size.shoe_size} value={size.shoe_size} key={size.shoe_size} />);
              if (sizeRecommendation && Object.values(size).includes(sizeRecommendation[contour.leading_system].toString())) {
                newShoeSize = sizeRecommendation[contour.leading_system];
              }
              // Only update the leading system if it's not already set
              if (!newContourLeadingSizeSystem || newContourLeadingSizeSystem != contour.leading_system) {
                newContourLeadingSizeSystem = contour.leading_system;
              }
            });
          }
        });
      }
    }
    setSizes(sizesArray);
    setShoeSize(newShoeSize);
    setContourLeadingSizeSystem(newContourLeadingSizeSystem);
  }, [contours, insoleType]);
  const handleSubmission = () => {
    const data = {
      ...(currentUser && currentUser.email ? { onefid: currentUser.email } : currentUser && currentUser.uuid && { uuid: currentUser.uuid }),
      shoe_size: shoeSize,
      contour_id: insoleType,
      weight: weight,
      config: currentScanner.insoleConfig && currentScanner.insoleConfig,
      sizing_system: contourLeadingSizeSystem,
      age_group: "adults",
      gender: (currentUser && currentUser.gender) || newScanState.gender === "male" ? "m" : "f",
      auto_print: currentScanner.auto_print ? currentScanner.auto_print : false,
    };
    ProductService.postInsoleCreation(data)
      .then((response): any => {
        console.log("INSOLE CREATION RESPONSE =", response);
        const cartData = {
          type: "standard",
          store_id: storeID,
          ...(currentUser && currentUser.email ? { email: currentUser.email } : { anonymous_user_uuid: currentScan && currentScan.customer_uuid }),
        };
        const insoleCartItemData = {
          type: "custom-insole",
          insole_set: response.insoleset_id,
        };
        ProductService.createCart(cartData)
          .then((cart: any) => {
            setCartUuid(cart.uuid);
            console.log("CART =", cart);
            ProductService.addCartItem(cart.uuid, insoleCartItemData)
              .then((cartItem: any) => {
                console.log("CART ITEM =", cartItem);
                setInsoleCreated(true);
              })
              .catch((cartItemError: any) => {
                console.log("CART ITEM ERROR =", cartItemError);
              });
          })
          .catch((cartError: any) => {
            console.log("CART ERROR =", cartError);
          });
      })
      .catch((error: any) => {
        console.log("INSOLE CREATION ERROR =", error);
      });
  };

  const handleGoBack = () => {
    const orderData = {
      type: "standard",
      store_id: storeID,
      ...(currentUser && currentUser.employeeNumber ? { employee_number: currentUser.employeeNumber } : { anonymous_user_uuid: currentUser && currentUser.uuid }),
    };
    ProductService.completeOrder(cartUuid, orderData);
    navigation.navigate("MainApp", { screen: "Measurements" });
  };

  return (
    <ImageBackground source={background} style={Styles.backgroundImage} imageStyle={{ opacity: 0.4 }}>
      <LayoutWrapper showHeaderCloseButton={false} showHeaderLogo={true} showBackButton={false}>
        <View style={styles.modalWrapper}>
          <TouchableOpacity style={styles.closeButton} onPress={() => navigation.goBack(null)}>
            <Image source={Icons.closeIconSquare} style={styles.iconContainer} />
          </TouchableOpacity>
          {!insoleCreated ? (
            <View style={styles.formWrapper}>
              <Text style={styles.titleText}>{i18n.t("createInsole")}</Text>
              <Image source={{ uri: recommendation && recommendation.image }} style={styles.insoleImage} />
              <View style={styles.inputsWrapper}>
                <Text style={styles.labelText}>{i18n.t("insoleType")}:</Text>
                <Picker style={styles.picker} selectedValue={insoleType} onValueChange={(itemValue) => setInsoleType(itemValue)}>
                  {contourItems}
                </Picker>
              </View>
              <View style={styles.inputsWrapper}>
                <Text style={styles.labelText}>{i18n.t("shoeSize")}:</Text>
                <Picker style={styles.picker} selectedValue={shoeSize} onValueChange={(itemValue) => setShoeSize(itemValue)}>
                  {sizes}
                </Picker>
              </View>
              <View style={styles.inputsWrapper}>
                <Text style={styles.labelText}>{i18n.t("weight")}:</Text>
                <Picker style={styles.picker} selectedValue={weight} onValueChange={(itemValue) => setWeight(itemValue)}>
                  {Array.from({ length: 24 }, (_, i) => (
                    <Picker.Item label={(i * 5 + 35).toString() + " kg"} value={i * 5 + 35} key={i} />
                  ))}
                </Picker>
              </View>
              <TouchableOpacity style={[styles.button, { backgroundColor: Colors.mainColorDark.color }]} onPress={handleSubmission}>
                <Text style={styles.buttonText}> {i18n.t("auth.confirm")} </Text>
              </TouchableOpacity>
              {errorMessage && <Text style={styles.error}>{errorMessage}</Text>}
            </View>
          ) : (
            <View style={styles.contentWrapper}>
              <Image style={styles.imageContainer} source={Icons.checkmarkIconCircle} />
              <Text style={[styles.successText, Fonts.mLight, Colors.black, Fonts.center]}>{i18n.t("insoleCreation")}</Text>
              <TouchableOpacity style={[styles.button, { backgroundColor: Colors.mainColorDark.color }]} onPress={() => handleGoBack()}>
                <Text style={styles.buttonText}> {i18n.t("mainScreen")} </Text>
              </TouchableOpacity>
            </View>
          )}
        </View>
      </LayoutWrapper>
    </ImageBackground>
  );
};

const styles = StyleSheet.create({
  closeButton: {
    width: "7%",
    height: "12%",
    position: "absolute",
    right: -5,
    top: 0,
    zIndex: 10,
    paddingTop: "3.5%",
    paddingLeft: "2%",
  },
  iconContainer: {
    width: "100%",
    height: "100%",
    resizeMode: "contain",
  },
  modalWrapper: {
    flex: 1,
    width: "57%",
    flexDirection: "row",
    justifyContent: "center",
    paddingLeft: "1.6%",
    alignSelf: "center",
  },
  formWrapper: {
    width: "90%",
    height: "90%",
    padding: "2%",
    alignSelf: "center",
    backgroundColor: "white",
  },
  contentWrapper: {
    width: "45%",
    height: "75%",
    alignSelf: "center",
    justifyContent: "space-evenly",
  },
  inputsWrapper: {
    width: "95%",
    height: "10%",
    marginTop: "2%",
    paddingLeft: "4%",
    paddingRight: "2%",
    backgroundColor: "#f0eff1",
    borderRadius: 6,
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    alignSelf: "center",
  },
  titleText: {
    fontFamily: "Montserrat-Black",
    fontSize: 21,
    fontWeight: "900",
    textAlign: "center",
    textTransform: "uppercase",
  },
  labelText: {
    width: "13%",
  },
  picker: {
    width: "90%",
    height: "50%",
    backgroundColor: "#f0eff1",
    borderRadius: 6,
    marginLeft: "5%",
  },
  checkboxField: {
    width: "95%",
    height: "10%",
    marginTop: "2%",
    paddingRight: "2%",
    borderRadius: 6,
    alignItems: "flex-start",
    flexDirection: "row",
    alignSelf: "center",
  },
  insoleImage: {
    height: "18%",
    width: "20%",
    resizeMode: "contain",
    alignSelf: "center",
  },
  imageContainer: {
    resizeMode: "contain",
    height: 100,
    width: 150,
    alignSelf: "center",
  },
  button: {
    width: 195,
    height: 42,
    borderRadius: 17,
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    marginTop: 10
  },
  buttonText: {
    fontFamily: "Montserrat-Regular",
    fontSize: 12,
    textAlign: "center",
    color: "#ffffff",
    textTransform: "uppercase",
  },
  successText: {
    fontSize: 29,
    fontWeight: "300",
    lineHeight: 50,
  },
  error: {
    fontFamily: "Montserrat-Regular",
    fontSize: 12,
    textAlign: "center",
    textTransform: "uppercase",
    color: "red",
    marginTop: "4%",
  },
});

export default CreationModal;
