import React, { useState, useEffect } from "react";
import { Image, View, StyleSheet, Text, ActivityIndicator, TouchableOpacity, PixelRatio, ImageBackground, Platform } from "react-native";
import { useGlobalState } from "../../shared/state";
import { Fonts } from "../../styles/font.styles";
import { background, Icons } from "../../assets";
import { Styles } from "../../styles";
import SizeScale from "../../components/SizeScale/SizeScale";
import * as WebBrowser from "expo-web-browser";
import { useNavigation } from "@react-navigation/native";
import { insoleCategories } from "../InsoleRecommendation/InsoleRecommendation";
import LayoutWrapper from "../../components/LayoutWrapper/LayoutWrapper";
import ProductService from "../../shared/services/products-service";
import { Picker } from "@react-native-picker/picker";
import BouncyCheckbox from "react-native-bouncy-checkbox";
import { getValidIndex, I18N } from "../../shared/util";

const ProductDetail: React.FC<any> = (route): JSX.Element => {
  const { params } = route.route;
  const [language] = useGlobalState("language");
  const [currentMeasurements] = useGlobalState("currentMeasurements");
  const [currentScanner] = useGlobalState("currentScanner");
  const [allProducts] = useGlobalState("allProducts");
  const [Colors] = useGlobalState("colors");
  const [, setIsOrthoOrder] = useGlobalState("isOrthoOrder");
  const [selectedShoe, setSelectedShoe] = useGlobalState("selectedShoe");
  const i18n = I18N(language);
  const navigation = useNavigation();
  const [product] = useState<any>((params && params.product) || selectedShoe || undefined);
  const [warehouseBins, setWarehouseBins] = useState<string[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [sizes, setSizes] = useState<React.ReactNode[]>();
  const [availableSizes, setAvailableSizes] = useState<any>();
  const [shoeSize, setShoeSize] = useState<string>("");
  const [, setInsole] = useState<string>("");
  const [insoleGtin, setInsoleGtin] = useState<string>(product.isInsole && product?.gtin);
  const [toggleCheckBox, setToggleCheckBox] = useState(false);
  const isInsoleDetail = params && params.isInsole;

  const primarySize: any = product.leading_system === "eu" ? product.size.eu : product.leading_system === "uk" ? product.size.uk : product.size.us;
  const secondarySize: any =
    currentScanner.enableSecondarySize || !("enableSecondarySize" in currentScanner)
      ? product.leading_system === "eu"
        ? product.size.secondary_eu
        : product.leading_system === "uk"
        ? product.size.secondary_uk
        : product.size.secondary_us
      : undefined;
  const price: any = product.price || undefined;
  const salePrice: any = product.salePrice || undefined;

  const EnableOrderButton: boolean =
    currentScanner.brandsToOrder && currentScanner.brandsToOrder.length > 0
      ? currentScanner.brandsToOrder.toLocaleString().toLowerCase().split(",").includes(params.product.brand.toLowerCase())
        ? true
        : false
      : true;
  
  const insoleCategoryIndex = currentMeasurements
    ? Math.min(
        getValidIndex(currentMeasurements.measurements[0].insole_recommendation),
        getValidIndex(currentMeasurements.measurements[1].insole_recommendation)
      ) === Infinity
      ? 1 // If both indices are -1, return 1
      : Math.min(
          getValidIndex(currentMeasurements.measurements[0].insole_recommendation),
          getValidIndex(currentMeasurements.measurements[1].insole_recommendation)
        )
    : 1;

  // if categoryToRecommend list is empty or undefined we display isoles for all product-category
  const isRecommendedForCategory = (): boolean =>
    !currentScanner.insoleRecommendation.categoryToRecommend
      ? true
      : currentScanner.insoleRecommendation.categoryToRecommend.includes(product.categoryName?.toLowerCase()) || !currentScanner.insoleRecommendation.categoryToRecommend.length
      ? true
      : false;

  const displayInsoleRecommendation: boolean =
    insoleCategories[insoleCategoryIndex] !== "none" && insoleCategories[insoleCategoryIndex] !== "special" && currentScanner.insoleRecommendation && isRecommendedForCategory();

  useEffect(() => {
    if (product) {
      setLoading(false);
      setSelectedShoe(product);
      if (currentScanner.displayStock) getProductWhereHouseBin();
    }
  }, []);

  const getProductWhereHouseBin = () => {
    var warehouseBinsArray: any[] = [];
    allProducts.map((products) => {
      products.details.map((detail: any) => {
        if (product.gtin === detail.gtin && currentScanner["storeid"] === detail.store_id) detail.warehouse_bin ? warehouseBinsArray.push(detail.warehouse_bin) : undefined;
      });
    });
    setWarehouseBins(warehouseBinsArray);
  };

  const orderProduct = () => {
    setIsOrthoOrder(false);
    navigation.navigate("Order", { GTIN: product?.gtin, INCLUDE_INSOLE: toggleCheckBox, isInsoleOrder: product.isInsole, insoleGtin: insoleGtin });
  };
  const orthoOrderProduct = () => {
    setIsOrthoOrder(true);
    navigation.navigate("OrthoRegisterModal");
  };

  const customiseProduct = () => {
    navigation.navigate("CustomiseProducOrder", { GTIN: product?.gtin });
  };
  const openShop = (gtin?: string, size?: string) =>
    WebBrowser.openBrowserAsync(`https://desma.expivi.com/product/perfectknit3d/?gtin=${gtin}&size=${size}`, { windowFeatures: { width: 1200, height: 1000 } }).catch((err) =>
      console.error("An error occurred", err)
    );

  useEffect(() => {
    const fetchAvailableSizes = async () => {
      try {
        const response = product && (await ProductService.getStoreProductsbyMpn(product?.storeID, product?.mpn));
        const availableSizes = response[0].details;
        setAvailableSizes(availableSizes);
      } catch (error) {
        console.error("Error fetching insole contour IDs:", error);
      }
    };

    !product.isInsole && fetchAvailableSizes();
  }, [product]);

  useEffect(() => {
    let sizesArray: JSX.Element[] = [<Picker.Item label="" value="" key="" />];
    let newShoeSize = "";

    if (availableSizes && availableSizes.length > 0) {
      availableSizes.forEach((size: any) => {
        const sizeValue = product.leading_system === "eu" ? size.eu : product.leading_system === "uk" ? size.uk : size.us;
        sizesArray.push(<Picker.Item label={sizeValue} value={size.gtin} key={size.gtin} />);

        if (sizeValue === primarySize) {
          newShoeSize = size.gtin;
        }
      });
    } else if (product.isInsole) {
      Object.keys(product.insoleSizes).map((size) => {
        sizesArray.push(<Picker.Item label={size} value={product.insoleSizes[size]} key={product.insoleSizes[size]} />);
        if (size === product.size) {
          newShoeSize = product.insoleSizes[size];
        }
      });
    }

    setSizes(sizesArray);

    // If primarySize exists in availableSizes, set it as the selected shoeSize
    if (newShoeSize) {
      setShoeSize(newShoeSize);
    } else if (sizesArray.length > 1) {
      // Otherwise, default to the first available size
      setShoeSize(sizesArray[1]?.props.value || "");
    }
  }, [availableSizes]);

  const handleSizeSelection = (selectedItem: any) => {
    if (product.isInsole) {
      setShoeSize(selectedItem);
      setInsoleGtin(selectedItem);
    } else if (toggleCheckBox) {
      const sizeObject = availableSizes.find((size: any) => size.gtin === selectedItem);
      setShoeSize(sizeObject.gtin);
      const stateSizeObject = selectedShoe.size;
      stateSizeObject.eu = sizeObject.eu;
      setSelectedShoe((prev: any) => ({ ...prev, size: stateSizeObject, gtin: selectedItem }));
      setInsoleGtin(extensionProductData.sizes[sizeObject.eu]);
    } else {
      const sizeObject = availableSizes.find((size: any) => size.gtin === selectedItem);
      setShoeSize(sizeObject.gtin);
      const stateSizeObject = selectedShoe.size;
      stateSizeObject.eu = sizeObject.eu;
      setSelectedShoe((prev: any) => ({ ...prev, size: stateSizeObject, gtin: selectedItem }));
    }
  };

  useEffect(() => {
    if (currentMeasurements) {
      var insoleCategoryIndex = Math.min(
        insoleCategories.indexOf(currentMeasurements.measurements[0].insole_recommendation),
        insoleCategories.indexOf(currentMeasurements.measurements[1].insole_recommendation)
      );
      setInsole(insoleCategories[insoleCategoryIndex]);
    }
  }, [currentMeasurements]);

  const genderForInsoleFilter = product && product.productGender === "f" ? "female" : "unisex";
  var newInsoleDataStructure: any = currentScanner.insoleRecommendation && currentScanner.insoleRecommendation.insoles[genderForInsoleFilter];
  var extensionProductData: any = newInsoleDataStructure
    ? newInsoleDataStructure[insoleCategories[insoleCategoryIndex]]
    : currentScanner.insoleRecommendation && currentScanner.insoleRecommendation.insoles.find((insole: any) => insole.id.toLowerCase() === `${insoleCategories[insoleCategoryIndex]}`);

  const handleIncludeInsole = () => {
    const shoeSize = selectedShoe.size && selectedShoe.size.eu
    setToggleCheckBox(!toggleCheckBox);
    extensionProductData && extensionProductData.sizes && setInsoleGtin(extensionProductData.sizes[shoeSize]);
  }
  /**
   * @description function to render the extension card
   */
  const renderExtensionCard = () => {
    return (
      <View style={styles.extensionCard}>
        <Text style={[styles.productCardNameText, { textAlign: "center", marginTop: 4 }]}>BESTELLEN</Text>
        <View style={styles.advertisingProductCard}>
          {/* <Image source={Icons.advertisingImage} style={styles.imageContainer} /> */}
          {/* {secondarySize ? (
            <>
              <Text style={Fonts.textHuge}>{i18n.t("secondaryRecommendatiom.yourSize")}</Text>
              <Text style={styles.secondaryRecomendationText}>{i18n.t("secondaryRecommendatiom.message1")}</Text>
              <Text style={styles.secondaryRecomendationText}>
                {i18n.t("secondaryRecommendatiom.message2")} {secondarySize < primarySize ? secondarySize : primarySize}{" "}
              </Text>
              <Text style={styles.secondaryRecomendationText}>
                {i18n.t("secondaryRecommendatiom.message3")} {secondarySize > primarySize ? secondarySize : primarySize}
              </Text>
            </>
          ) : (
            <Text style={Fonts.textGigantic}>{i18n.t("advertisingOnefid")}</Text>
          )} */}
          {/* : <Image source={Icons.advertisingImage} style={styles.imageContainer} /> */}
          {/* <Text style={Fonts.textHuge}>{i18n.t("recommendedInsole")} {insole}</Text> */}
          {extensionProductData ? (
            <>
              {product && (product.suitableStandardInsole || product.suitableStandardInsole === null || product.suitableStandardInsole === undefined) && !isInsoleDetail && (
                <>
                  <View style={styles.recommendationDetailContainer}>
                    <Image source={{ uri: extensionProductData.image }} style={styles.imageContainer} />
                    <Text style={[styles.productCardNameText, { textAlign: "center" }]}>{extensionProductData.name}</Text>
                    {extensionProductData.price != undefined && <Text style={styles.productCardPriceText}>{`${(i18n.t("price"))}: ${extensionProductData.price}€`}</Text>}
                  </View>

                  {currentScanner.enableOrder && EnableOrderButton && (
                    <View style={styles.checkboxWrapper}>
                      <BouncyCheckbox
                        innerIconStyle={{ borderColor: Colors.mainColorLight.color }}
                        fillColor={Colors.mainColorLight.color}
                        isChecked={toggleCheckBox}
                        onPress={() => handleIncludeInsole()}
                        text={i18n.t("addItem")}
                        textStyle={styles.checkBoxText}
                      />
                    </View>
                  )}
                </>
              )}
            </>
          ) : (
            <Text style={[styles.productCardNameText, { textAlign: "center" }]}>{i18n.t("insole.recommendation.0.conclusion")}</Text>
          )}
        </View>
        {currentScanner.enableExpiviShop && product.brand === "DESMA" ? (
          <TouchableOpacity style={styles.basketContainer} onPress={() => openShop(product?.gtin, primarySize)}>
            <View style={styles.basketContainerView}>
              <Image source={Icons.basketIcon} style={styles.basketIcon}></Image>
              <Text style={styles.basketContainerText}>{i18n.t("expiviConfigurator")}</Text>
            </View>
          </TouchableOpacity>
        ) : (
          <View style={styles.buttonContainer}>
            {currentScanner.enableOrder && EnableOrderButton && (
              <TouchableOpacity style={[styles.button, { margin: 0 }, styles.halfWidth]} onPress={() => (product?.msi ? customiseProduct() : orderProduct())}>
                <View style={styles.basketContainerView}>
                  <Image source={Icons.basketIcon} style={styles.basketIcon} />
                  <Text style={styles.basketContainerText}>{i18n.t("order now")}</Text>
                </View>
              </TouchableOpacity>
            )}
            {currentScanner && currentScanner.enableOrthopedicButton && !product.isInsole && (
              <TouchableOpacity style={[styles.button, { margin: 0 }, styles.halfWidth]} onPress={() => orthoOrderProduct()}>
                <View style={styles.basketContainerView}>
                  <Image source={Icons.basketIcon} style={styles.basketIcon} />
                  <Text style={styles.basketContainerText}>{i18n.t("orthoOrder")}</Text>
                </View>
              </TouchableOpacity>
            )}
            {currentScanner.enableOrder && EnableOrderButton && (
              <>
                <View style={[styles.button, styles.halfWidth, { margin: 0, flexWrap: "wrap" }]}>
                  <Text>{i18n.t("listOfSizes")}</Text>
                  <Picker style={styles.picker} selectedValue={shoeSize} onValueChange={(itemValue) => handleSizeSelection(itemValue)}>
                    {sizes}
                  </Picker>
                </View>
                {/* <TouchableOpacity
                  style={[styles.button, styles.halfWidth, {margin: 0}]}
                  onPress={() => navigation.navigate("ChangeSizeModal", { GTIN: product?.gtin, MPN: product?.mpn, STORE_ID: product?.storeID })}
                >
                  <View style={styles.basketContainerView}>
                    <Image source={Icons.soleIcon} style={styles.basketIcon} />
                    <Text style={styles.basketContainerText}>{i18n.t("changeSizeButton")}</Text>
                  </View>
                </TouchableOpacity> */}
              </>
            )}
          </View>
        )}
      </View>
    );
  };

  if (isLoading) {
    return (
      <ImageBackground source={background} style={Styles.backgroundImage} imageStyle={{ opacity: 0.4 }}>
        <LayoutWrapper showHeaderCloseButton={false} showBackButton={true} showHeaderLogo={true}>
          <View style={styles.loader}>
            <ActivityIndicator size="large" color={Colors.mainColorLight.color} />
          </View>
        </LayoutWrapper>
      </ImageBackground>
    );
  }
  return (
    <ImageBackground source={background} style={Styles.backgroundImage} imageStyle={{ opacity: 0.4 }}>
      <LayoutWrapper showHeaderCloseButton={false} showHeaderLogo={true} showBackButton={true}>
        <View style={styles.wrapper}>
          <View style={styles.productContainer}>
            {product && (
              <View style={styles.card}>
                <View style={styles.productImageWrapper}>
                  <View style={styles.imageGallery}>
                    <Image source={{ uri: product.image_url }} style={styles.productImageContainer} />
                  </View>
                </View>
                <View style={styles.productDetailsWrapper}>
                  <View style={styles.productCardDetails}>
                    <Text style={styles.productDescriptionText}>{product.description}</Text>
                    <Text adjustsFontSizeToFit style={styles.productCardNameText}>
                      {product.name}
                    </Text>
                    {product.width_name != undefined && <Text style={styles.widthNameText}>{product.width_name}</Text>}
                    {product.price != undefined && (
                      <View style={{ flexDirection: "row" }}>
                        <Text style={styles.productCardPriceText}>{`${i18n.t("price")}: `}</Text>
                        {salePrice && price && salePrice < price ? (
                          <>
                            <Text style={[styles.productCardPriceText, { textDecorationLine: "line-through" }]}>{`${price}€ `}</Text>
                            <Text style={[styles.productCardPriceText, { color: "red" }]}>{`${salePrice}€`}</Text>
                          </>
                        ) : (
                          <Text style={styles.productCardPriceText}>{`${price}€`}</Text>
                        )}
                      </View>
                    )}
                    <Text style={styles.productCardManufacturerText}>
                      {product.brand} - {selectedShoe.gtin || product.gtin}
                    </Text>
                    {warehouseBins.length > 0 && <Text style={styles.productCardPriceText}>{warehouseBins.map((w) => `${w}  `)}</Text>}
                  </View>
                  <View style={styles.sizeWrapper}>
                    <View style={styles.soleIconContainer}>
                      <Image source={Icons.soleIcon} style={styles.soleIcon}></Image>
                      <Text style={styles.soleIconText}>{product.last_width_category}</Text>
                    </View>
                    <View style={[styles.sizeContainer, { backgroundColor: Colors.mainColorDark.color }]}>
                      <SizeScale
                        caption={"EU"}
                        valueCount={5}
                        categories={
                          secondarySize
                            ? secondarySize < primarySize
                              ? [
                                  { name: secondarySize, value: secondarySize },
                                  { name: primarySize, value: primarySize },
                                ]
                              : [
                                  { name: primarySize, value: primarySize },
                                  { name: secondarySize, value: secondarySize },
                                ]
                            : [
                                { name: "", value: 0 },
                                { name: primarySize, value: 2 },
                                { name: "", value: 4 },
                              ]
                        }
                        values={{ left: 2 }}
                      />
                    </View>
                  </View>
                </View>
              </View>
            )}
          </View>
          <View style={styles.subContainer}>{renderExtensionCard()}</View>
        </View>
      </LayoutWrapper>
    </ImageBackground>
  );
};

export default ProductDetail;

const styles = StyleSheet.create({
  wrapper: {
    height: "95%",
    width: "80%",
    flexDirection: "row",
    alignSelf: "center",
    backgroundColor: "white",
    shadowColor: "#000",
    shadowOffset: { width: 6 / PixelRatio.get(), height: 6 / PixelRatio.get() },
    shadowOpacity: 0.16,
    elevation: 1,
    opacity: 1,
  },
  productContainer: {
    height: "100%",
    width: "65%",
    shadowColor: "#000",
    shadowOffset: { width: 6 / PixelRatio.get(), height: 6 / PixelRatio.get() },
    shadowOpacity: 0.16,
    elevation: 1,
    opacity: 1,
  },
  subContainer: {
    height: "100%",
    width: "35%",
    justifyContent: "flex-end",
  },
  card: {
    height: "98.5%",
    justifyContent: "space-between",
    backgroundColor: "white",
  },
  extensionCard: {
    height: "98.5%",
    justifyContent: "space-between",
  },
  productImageWrapper: {
    height: "65%",
  },
  sizeWrapper: {
    height: "100%",
    width: "30%",
    flexDirection: "row",
  },
  soleIconContainer: {
    width: "50%",
    height: "60%",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "flex-end",
  },
  soleIcon: {
    width: "100%",
    height: "80%",
    resizeMode: "contain",
    zIndex: 10,
  },
  soleIconText: {
    ...Fonts.mSemiBold,
    fontSize: 16,
    fontWeight: "900",
    position: "absolute",
    right: "45%",
    bottom: "40%",
    zIndex: 11,
  },
  imageContainer: {
    width: "90%",
    height: "90%",
    resizeMode: "contain",
  },
  productImageContainer: {
    width: "100%",
    height: "100%",
    resizeMode: "contain",
  },
  productDetailsWrapper: {
    height: "35%",
    width: "100%",
    flexWrap: "wrap",
    marginTop: 9,
  },
  productCardDetails: {
    height: "100%",
    width: "70%",
    alignSelf: "flex-end",
    padding: "2%",
  },
  productCardNameText: {
    textAlign: "left",
    fontFamily: "Montserrat-Black",
    fontSize: 16,
    marginTop: 10,
  },
  productCardPriceText: {
    fontFamily: "Montserrat-SemiBold",
    fontSize: 14,
  },
  productCardManufacturerText: {
    fontFamily: "Montserrat-Black",
    fontSize: 12,
  },
  sizeContainer: {
    alignSelf: "flex-end",
    height: "55%",
    width: "50%",
  },
  loader: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    flexWrap: "wrap",
    paddingTop: "2%",
    paddingLeft: "1%",
  },
  productDescriptionText: {
    fontFamily: "Montserrat-Light",
    fontSize: 13,
    paddingBottom: "5%",
    overflow: "scroll",
  },
  basketContainer: {
    width: "98.9%",
    height: "20%",
    alignSelf: "flex-end",
    borderWidth: 1,
    borderColor: "#d6d7da",
    shadowColor: "#000",
    shadowOffset: {
      width: 0.2,
      height: 1,
    },
    shadowOpacity: 0.25,
    shadowRadius: 1,
    elevation: 1,
    backgroundColor: "white",
    marginTop: "2%",
  },
  button: {
    height: "48%",
    margin: "1%",
    borderWidth: 1,
    borderColor: "#d6d7da",
    shadowColor: "#000",
    shadowOffset: {
      width: 0.2,
      height: 1,
    },
    shadowOpacity: 0.25,
    shadowRadius: 1,
    elevation: 1,
    backgroundColor: "white",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  buttonContainer: {
    flexDirection: "row",
    width: "100%",
    height: "40%",
    alignSelf: "flex-end",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  halfWidth: {
    width: "48%",
  },
  fullWidth: {
    width: "99%",
  },
  basketContainerView: {
    flex: 1,
    justifyContent: "space-evenly",
    alignItems: "center",
    alignContent: "center",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  basketIcon: {
    width: 45,
    height: 40,
    resizeMode: "contain",
    alignSelf: "center",
  },
  basketContainerText: {
    fontFamily: "Montserrat-Black",
    fontSize: 14,
  },
  advertisingProductCard: {
    justifyContent: "space-evenly",
    alignItems: "center",
    alignSelf: "center",
    height: "55%",
    width: "90%",
  },
  imageGallery: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  secondaryRecomendationText: {
    fontFamily: "Montserrat-Regular",
    fontSize: 15,
    textAlign: "center",
  },
  widthNameText: {
    fontFamily: "Montserrat-Regular",
    fontSize: 14,
    fontWeight: "600",
    right: "-36%",
    bottom: 140,
    position: "absolute",
  },
  picker: {
    width: "100%",
    height: "45%",
    backgroundColor: "#f0eff1",
    borderRadius: 6,
    border: "none",
  },
  recommendationDetailContainer: {
    height: "85%",
    width: "100%",
    padding: 5,
    alignItems: "center",
  },
  checkboxWrapper: {
    justifyContent: "center",
    width: "95%",
    flexDirection: "row",
    alignContent: "center",
  },
  checkBoxText: {
    fontFamily: "Montserrat-Regular",
    fontSize: 12,
    color: "#000000",
    fontWeight: "100",
    textDecorationLine: "none",
  },
});
